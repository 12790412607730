import React from 'react';
import basicWebImage from '../../assets/basicwebimage.png';
import ListOfButtons from '../../TagCreator/ListOfButtons';
function ListComponent({data}) {
const listOfButtons = ListOfButtons();



 // Utility function to get YouTube embed link
 const getYouTubeEmbedLink = (watchLink) => {
  let videoId;
  let embedLink = '';
  if (watchLink.includes("v=")) {
    videoId = watchLink.split("v=")[1].split('&')[0];
    embedLink = `https://www.youtube.com/embed/${videoId}`;
  } else if (watchLink.includes("youtu.be")) {
    videoId = watchLink.split("/").pop();
    embedLink = `https://www.youtube.com/embed/${videoId}`;
  }
  return embedLink;
};

// Utility function to get Spotify embed link
const getSpotifyEmbedLink = (spotifyLink) => {
  let embedLink = spotifyLink.replace("open.spotify.com", "open.spotify.com/embed");
  return embedLink;
};

const renderItem = (item, index) => {
  // Define a custom style for the first item to reduce its top margin/padding
  const firstItemStyle = index === 0 ? { marginTop: '-23px' } : {}; // Adjust the value as needed

  switch (item.type) {
    case 'text':
      return <div className="text-center p-1" style={firstItemStyle}>{item.data}</div>;
    case 'video':
      return (
        <div className="aspect-w-16 aspect-h-9" style={firstItemStyle}>
          <iframe
            src={getYouTubeEmbedLink(item.data)}
            frameBorder="0"
            className="justify-center items-center w-full p-2"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      );
      case 'link':
        const truncatedText = item.data.length > 20 ? `${item.data.substring(0, 20)}...` : item.data;
        return (
          <a 
            href={item.data} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-blue-500 hover:underline" 
            style={firstItemStyle}
            title={item.data} // Show full URL on hover
          >
            {truncatedText}
          </a>
        );
    case 'music':
      return (
        <div className='p-1'>
        <iframe
          src={getSpotifyEmbedLink(item.data)}
          width="100%"
          height="80"
          frameBorder="0"
          allowTransparency="true"
          allow="encrypted-media"
          style={firstItemStyle}
        ></iframe></div>
      );
    default:
      return null;
  }
};
var linkDivSetup = <div></div>
console.log("Data!",data);

const baseStyles = listOfButtons[data.ButtonStyle.buttonStyleId].style;
  const additionalStyles = {
    backgroundColor: data.ButtonStyle.buttonColor,
    color: data.ButtonStyle.fontColor,
  };
  var linkDivSetup = <div></div>

  return (linkDivSetup = 
   
      <div 
        className="inline-flex items-center justify-center  outline outline-2 w-full" 
        id='tagModule' 
        style={Object.assign({}, baseStyles, additionalStyles)}
      >
        <div className={data.ButtonStyle.fontClassName}>
          &nbsp; 
          {data.list.map((item, index) => (
            <div key={index} className=" ">
              {renderItem(item, index)}
            </div>
          ))}
        </div>
      </div>
 
  );
}


export default ListComponent;




